package com.flat14.content


import androidx.compose.runtime.Composable
import com.flat14.style.*
import com.flat14.values.*
import org.jetbrains.compose.web.attributes.ATarget
import org.jetbrains.compose.web.attributes.target
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*

@Composable
fun topBarExtended() {
    Section({
        style { classes("responsive-topbar-extended") }}) {
        Div({
            classes(WtContainer.wtContainer) }) {
            Div({
                classes(WtRows.wtRow, WtRows.wtRowSizeM, "alignment-content-space-between")
            }) {
                Div({
                    classes("style_logo")
                }) {
                    A(href = "#"){
                        logo()
                    }
                }
                Div {
                    Ul({
                        classes("navigation-li")
                    }) {
                        getOptionsLinks().forEach { optionTextLink(it) }
                    }
                }
            }
        }
    }
}

@Composable
private fun logo() {
    Img(src = "data/logo_pequeno.png", attrs = {
        classes("top-image")
    })
}

@Composable
private fun optionTextLink(link: OptionLink) {
    Li {
        A(attrs = {
            classes(WtTexts.wtOptionTextButtonItem)
            target(ATarget.Self)
        }, href = link.url) {
            Button(attrs =
            {
                classes("mdl-button", "mdl-js-button", "mdl-js-ripple-effect")
            }) {
                Text(link.text)
            }
        }
    }
}

private data class OptionLink(
    val id: String,
    val url: String,
    val text: String
)

private fun getOptionsLinks(): List<OptionLink> {
    return listOf(
        OptionLink(id = ID_BUILDING_CERTIFICATE, url = "#$ID_BUILDING_CERTIFICATE", text = BUILDING_CERTIFICATE),
        OptionLink(id = ID_WASTE_CERTIFICATE, url = "#$ID_WASTE_CERTIFICATE", text = WASTE_MANAGEMENT_CERTIFICATE),
        OptionLink(id = ID_SERVICES, url = "#$ID_SERVICES", text = SERVICES),
        OptionLink(id = ID_GALLERY, url = "#$ID_GALLERY", text = GALLERY),
        OptionLink(id = ID_CONTACTS, url = "#$ID_CONTACTS", text = CONTACTS)
    )
}