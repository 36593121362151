package com.flat14.content

import androidx.compose.runtime.Composable
import com.flat14.style.WtContainer
import com.flat14.values.*
import org.jetbrains.compose.web.css.maxWidth
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.*

@Composable
fun gallerySection() {
    Section({
        classes("section-padding")
        id(ID_GALLERY)
    }) {
        Div({
            classes(WtContainer.wtContainer)
            style { maxWidth(1294.px) }
        }) {
            headerText(GALLERY)
            Br()
            Div({ classes("gallery") }) {
                imageElement(IMAGE1, IMAGE1_SMALL)
                imageElement(IMAGE2, IMAGE2_SMALL)
                imageElement(IMAGE3, IMAGE3_SMALL)
                imageElement(IMAGE4, IMAGE4_SMALL)
                imageElement(IMAGE5, IMAGE5_SMALL)
                imageElement(IMAGE6, IMAGE6_SMALL)
                imageElement(IMAGE7, IMAGE7_SMALL)
                imageElement(IMAGE8, IMAGE8_SMALL)
                imageElement(IMAGE9, IMAGE9_SMALL)
                imageElement(IMAGE10, IMAGE10_SMALL)
            }
        }
    }
}

@Composable
fun imageElement(href: String, src: String) {
    A(href = href, attrs = {
        attr("data-lightbox", "mygallery")
    }) {
        Img(src = src)
    }
}