package com.flat14.content

import androidx.compose.runtime.Composable
import com.flat14.style.WtContainer
import com.flat14.values.*
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.rgb
import org.jetbrains.compose.web.dom.*

@Composable
fun servicesSection() {
    Section({
        id(ID_SERVICES)
        classes("section-padding") }) {
        Div({
            classes(WtContainer.wtContainer)
        }) {
            headerText(SERVICES)
            paragraphText(SERVICES_P)
            Ul({
                style {
                    color(rgb(87, 87, 87))
                }
            }) {
                Li { Text(TYPE1_SERVICES) }
                Li { Text(TYPE2_SERVICES) }
                Li { Text(TYPE3_SERVICES) }
                Li { Text(TYPE4_SERVICES) }
                Li { Text(TYPE5_SERVICES) }
            }
            paragraphText(SERVICES_INFO)
        }
    }
}