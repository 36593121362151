package com.flat14.content

import androidx.compose.runtime.Composable
import com.flat14.style.WtContainer
import com.flat14.values.*
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*

@Composable
fun constructionSection() {
    Section {
        Div({
            classes(WtContainer.wtContainer)
            id(ID_BUILDING_CERTIFICATE)
        }) {
            Div({ classes("section-padding") }) {
                H2({ classes("mdl-cell", "mdl-cell--12-col", "mdl-typography--headline") }) {
                    Text(BUILDING_CERTIFICATE)
                }
                paragraphText(BUILDING_CERTIFICATE_P)
                Ul({
                    style {
                        color(rgb(87, 87, 87))
                    }
                }) {
                    Li { Text(BUILDING_CERTIFICATE_CATEGORY1) }
                    Li { Text(BUILDING_CERTIFICATE_CATEGORY2) }
                }
                paragraphText(BUILDING_CERTIFICATE_INFO)
                Img(src = "data/alvara_construcao.jpeg", attrs = {
                    classes("section-padding")
                    classes("bottom-image")
                    style {
                        width(80.percent)
                    }
                })
            }
        }
    }
}