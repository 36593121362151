package com.flat14.content

import androidx.compose.runtime.Composable
import com.flat14.style.WtContainer
import com.flat14.values.*
import org.jetbrains.compose.web.attributes.ATarget
import org.jetbrains.compose.web.attributes.target
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*

@Composable
fun footer() {
    Section({
        style {
            backgroundColor(rgb(39, 39, 39))
            height(200.px)
        }
    }) {
        Div({
            classes(WtContainer.wtContainer)
            classes("alignment-content-space-between")
            style { display(DisplayStyle.Flex) }
        }) {
            Div({ classes("centering-vertically-horizontally-div") }) {
                footerParagraphText(COPYRIGHT)
            }
            Div({
                classes("centering-vertically-horizontally-div")
                style {
                    justifyContent(JustifyContent.SpaceBetween)
                    width(400.px)
                }}) {
                footerParagraphText(POWERED_BY)
                Div({ style {
                    display(DisplayStyle.Flex)
                    justifyContent(JustifyContent.SpaceEvenly)
                    width(150.px)
                }}){
                    footerContactItem(URL_PHONE, PHONE)
                    footerContactItem(URL_FACEBOOK, FACEBOOK)
                    footerInternalSocialItem(URL_WHATSAPP, "data/whatsapp_white.svg", 24.px)
                    footerInternalSocialItem(URL_INSTAGRAM, "data/instagram_white.svg", 24.px)
                }
            }
        }
    }
}

@Composable
private fun footerParagraphText(text: String) {
    P({
        classes("centering-vertically-horizontally-p")
        style {
            color(rgb(135, 135, 135))
        }
    }) {
        Text(text.trimIndent())
    }
}

@Composable
private fun footerContactItem(href: String, name: String) {
    A(attrs = {
        style {
            height(100.percent)
            display(DisplayStyle.Flex)
            alignItems(AlignItems.Center)
            color(rgb(255, 255, 255))
            textDecoration("none")
        }
        target(ATarget.Blank)
    }, href = href) {
        I({
            classes("material-icons")
        }) {
            Text(name)
        }
    }
}
@Composable
private fun footerInternalSocialItem(href: String, name: String, size: CSSNumeric) {
    A(attrs = {
        style {
            height(100.percent)
            display(DisplayStyle.Flex)
            alignItems(AlignItems.Center)
            color(rgb(255, 255, 255))
            textDecoration("none")
        }
        target(ATarget.Blank)
    }, href = href) {
        I{
            Img(name, attrs = {style { height(size) }})
        }
    }
}
