package com.flat14.content

import androidx.compose.runtime.Composable
import com.flat14.style.WtContainer
import com.flat14.values.*
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*

@Composable
fun wasteManagementSection() {
    Section({
        id(ID_WASTE_CERTIFICATE)
        style {
            backgroundColor(rgb(242, 242, 242))
            style {
                paddingTop(150.px)
                paddingBottom(150.px)
            }
        }
    }) {
        Div({ classes(WtContainer.wtContainer) }) {
            headerText(WASTE_MANAGEMENT_CERTIFICATE)
            paragraphText(WASTE_MANAGEMENT_P)
            Ul({
                style {
                    color(rgb(87, 87, 87))
                }
            }) {
                Li { Text(WASTE_CERTIFICATE_CATEGORY1) }
                Li {
                    Text(WASTE_CERTIFICATE_CATEGORY2)
                }
                Li {
                    Text(WASTE_CERTIFICATE_CATEGORY3)
                }
            }
            Div({ style { paddingTop(15.px) } }) {
                A(href = "data/alvara_residuos.pdf", attrs = {
                    classes("mdl-cell", "mdl-cell--6-col", "mdl-card__supporting-text")
                }) {
                    Text(WASTE_CERTIFICATE_INFO)
                }
            }
        }
    }
}