package com.flat14.content

import androidx.compose.runtime.Composable
import androidx.compose.web.events.SyntheticMouseEvent
import com.flat14.components.ContainerInSection
import com.flat14.style.WtContainer
import com.flat14.values.*
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.*
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.*

@Composable
fun sliderSection() {
    Div({ classes("slideshow-container") }) {
        loadImage("1/5", GRAVEL)
        loadImage("2/5", MACHINE)
        loadImage("3/5", WIP)
        loadImage("4/5", SAND)
        loadImage("5/5", PLASTIC)
    }
}

@Composable
fun loadImage(label: String, src: String){
    Div({ classes("mySlides", "fade") }) {
        Div({ classes("numbertext") }) {
            Text(label)
        }
        Img(src = src,
            attrs = {
                style {
                    width(100.vw)
                    height(600.px)
                    property("object-fit", "cover")
                }
            })
    }
}

var slideIndex = 0

suspend fun showSlides() {
    println("Current Slide $slideIndex")
    val slides = document.getElementsByClassName("mySlides")
    for (i in 0 until slides.length) {
        (slides[i] as HTMLDivElement).style.display = "none"
    }
    slideIndex++
    if (slideIndex > slides.length) {
        slideIndex = 1
    }
    (slides[slideIndex - 1] as HTMLDivElement).style.display = "block"
    delay(4000)
}