package com.flat14.content

import androidx.compose.runtime.Composable
import com.flat14.style.WtContainer
import com.flat14.values.*
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*

@Composable
fun introSection() {
    Section({
        style {
            backgroundColor(rgb(242, 242, 242))
        }
    }) {
        Div({ classes(WtContainer.wtContainer) }) {
            Div({ classes("section-padding") }) {

                headerText(HEADER_INTRO)
                paragraphText(INTRO_P1)
                paragraphText(INTRO_P2)
                paragraphText(INTRO_P3)

                Div({ classes("fill-parent-width") }) {
                    headerText(VISION)
                    paragraphText(VISION_P)
                }
                Div({ classes("fill-parent-width") }) {
                    headerText(MISSION)
                    paragraphText(MISSION_P)
                }
            }
        }
    }
}

@Composable
fun paragraphText(text: String) {
    P({
        classes("mdl-cell", "mdl-cell--6-col", "mdl-card__supporting-text", "no-padding")
        style {
            width(100.percent)
            lineHeight("1.6")
        }
    }) {
        Text(text.trimIndent())
    }
}

@Composable
fun headerText(text: String) {
    H2({
        classes("mdl-cell", "mdl-cell--12-col", "mdl-typography--headline")
        style {
            textAlign("left")
        }
    }) {
        Text(text)
    }
}