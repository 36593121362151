package com.flat14.content

import androidx.compose.runtime.Composable
import com.flat14.values.*
import kotlinx.browser.document
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import org.jetbrains.compose.web.dom.Text


@Composable
fun topBarCollapsed() {
    Section({
        id(HOME)
        classes("responsive-topbar-collapsed") }) {
        Div({
            style {
                width(100.percent)
                display(DisplayStyle.Flex)
            }
        }) {
            Div({ style { width(50.percent) } }) {
                createMenuList()
            }
            Div({ style { width(100.percent) } })
            {
                Div({
                    classes("blank-div")
                    onClick { showMenu() }
                })
            }
        }
        Div(attrs = {
            classes("head-menus")
        }) {
            Div {
                Img("data/menu-topbar.svg",
                    attrs = {
                        classes("topbar-menu-size")
                        onClick { showMenu() }
                    })
            }
            Div {
                A(href = "#$HOME") {
                    Img("data/logo_pequeno.png",
                        attrs = {
                            classes("logo-collapsed-menu")
                        })
                }
            }

        }
    }
}

@Composable
fun createMenuList() {
    Div {
        Ul({ classes("menu") }) {
            itemList(ID_BUILDING_CERTIFICATE, BUILDING_CERTIFICATE)
            itemList(ID_WASTE_CERTIFICATE, WASTE_MANAGEMENT_CERTIFICATE)
            itemList(ID_SERVICES, SERVICES)
            itemList(ID_GALLERY, GALLERY)
            itemList(ID_CONTACTS, CONTACTS)
        }
    }
}

fun showMenu() {
    document.querySelector(".menu")?.classList?.toggle("show-menu")
    document.querySelector(".head-menus")?.classList?.toggle(("show-head-menus"))
    document.querySelector(".blank-div")?.classList?.toggle("show-blank-div")
}

@Composable
fun itemList(url: String, text: String) {
    Li({ classes("Li-style") }) {
        A(href = "#$url", attrs = { classes("A-style") }) {
            Text(text)
        }
    }
}