package com.flat14

import org.jetbrains.compose.web.css.Style
import org.jetbrains.compose.web.renderComposable
import com.flat14.components.*
import com.flat14.content.*
import com.flat14.style.AppStylesheet
import kotlinx.coroutines.*

fun main() {
    renderComposable(rootElementId = "root") {
        Style(AppStylesheet)

        layout {
            topBarCollapsed()
            topBarExtended()
            sliderSection()
            introSection()
            constructionSection()
            wasteManagementSection()
            servicesSection()
            gallerySection()
            contactsSection()
            footer()
        }
    }

    GlobalScope.launch {
        while (true) {
            showSlides()
        }
    }
}