package com.flat14.content

import androidx.compose.runtime.Composable
import com.flat14.style.WtContainer
import com.flat14.values.*
import org.jetbrains.compose.web.attributes.ATarget
import org.jetbrains.compose.web.attributes.target
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.css.Color.black
import org.jetbrains.compose.web.dom.*

private const val MAPS_KEY = "AIzaSyCIDoYaph23IjIJ-rZDIoSNEzYNHlKjlic"

@Composable
fun contactsSection() {
    Section({
        style {
            backgroundColor(rgb(242, 242, 242))
        }
    }) {
        Div({
            classes(WtContainer.wtContainer)
            classes("section-padding")
        }) {
            H2 {
                Text(COME_VISIT_US)
            }
            maps()
        }
    }
    Section {
        Div({
            classes(WtContainer.wtContainer)
            classes("section-padding")
            id(ID_CONTACTS)
            classes("contacts_div")
        }) {
            Div({ style { width(40.percent) } }) {
                H2() {
                    Text(CONTACT_US)
                }
                Div({
                    style {
                        display(DisplayStyle.Flex)
                        justifyContent(JustifyContent.SpaceBetween)
                        paddingTop(10.px)
                        paddingBottom(10.px)
                        width(70.percent)
                    }
                }) {
                    contactsItems(URL_PHONE, PHONE)
                    contactsItems(URL_FACEBOOK, FACEBOOK)
                    internalSocialItem(URL_WHATSAPP, "data/whatsapp.svg", 120.px)
                }
            }
            Div({ style { width(30.percent) } }) {
                H5 {
                    Text(ADDRESS)
                }
                paragraphContactsText(ADDRESS1)
                paragraphContactsText(ADDRESS2)
                paragraphContactsText(ADDRESS3)
                H5 {
                    Text(CONTACTS)
                }
                Div({
                    style {
                        justifyContent(JustifyContent.SpaceBetween)
                        display(DisplayStyle.Flex)
                        backgroundColor(rgb(242, 242, 242))
                        padding(10.px)
                    }
                }) {
                    Div() {
                        paragraphContactsText(DIRECTOR)
                        paragraphContactsText(CONTAINERS)
                        P({style { padding(5.px) }})

                        paragraphContactsText(OFFICE)
                    }
                    Div() {
                        paragraphContactsText(DIRECTOR_PHONE)
                        paragraphContactsText(CONTAINERS_PHONE)
                        paragraphContactsText(OFFICE_PHONE)
                    }
                }
                Div({ style { padding(10.px) } }) {
                    H5 {
                        Text(EMAIL)
                    }
                    A(href = URL_GENERAL_EMAIL, attrs = {style { color(black) }}) {
                        Text(GENERAL_EMAIL)
                    }
                    P {  }
                    A(href = URL_COMMERCIAL_EMAIL, attrs = {style { color(black) }}) {
                        Text(COMMERCIAL_EMAIL)
                    }
                }
            }
        }
    }

}

@Composable
private fun maps() {
    Iframe(attrs = {
        attr(
            "src",
            "https://www.google.com/maps/embed/v1/place?key=$MAPS_KEY&q=Laranjeiro%20e%20Alcaide%2C%20Nariz%2C%20POrtugal"
        )
        attr("frameBorder", "no")
        attr("scrolling", "no")
        attr("marginHeight", "0")
        attr("marginWidth", "0")

        style {
            height(700.px)
            width(100.percent)
        }
    })
}

@Composable
fun paragraphContactsText(text: String, vararg classes: String) {
    P({
        classes(*classes)
        style { marginBottom(5.px) }
    }) {
        Text(text.trimIndent())
    }
}

@Composable
private fun contactsItems(href: String, name: String) {
    A(attrs = {
        style {
            height(100.percent)
            display(DisplayStyle.Flex)
            alignItems(AlignItems.Center)
            color(rgb(0, 0, 0))
            textDecoration("none")
        }
        target(ATarget.Blank)
    }, href = href) {
        I({
            classes("material-icons")
            style {
                fontSize(128.px)
            }
        }) {
            Text(name)
        }
    }
}

@Composable
private fun internalSocialItem(href: String, icon: String, size: CSSNumeric) {
    A(attrs = {
        style {
            height(100.percent)
            display(DisplayStyle.Flex)
            alignItems(AlignItems.Center)
            color(rgb(0, 0, 0))
            textDecoration("none")
            paddingLeft(10.px)
        }
        target(ATarget.Blank)
    }, href = href) {
        I{
            Img(icon, attrs = {style { height(size) }})
        }
    }
}
